import axios from "axios";
import React, { Component } from "react";
import {Modal, ModalBody, Input, FormGroup, Label, Row, Col, Alert} from "reactstrap";

import {API_TRACKING} from "../constants";
import {ClientThemeContext} from "../constants/themes";

import OrderInformationHeader from "./OrderInformationHeader";
import ProductsList from "./ProductsList";
import NestedModal from "./NestedModal";
import Loader from "./Loader";


class ReturnOrderModal extends Component {
  static contextType = ClientThemeContext;

  constructor(props) {
    super(props);

    this.state = {
      creating_return_in_progress: false,
      order_id: '',
      order_items_for_sending: [],
      return_email: '',
      return_reason: '',
      return_option: '',
      return_comment: '',
      return_additional_comments: '',
      return_order_ref: '',
      return_order_tracking_number: '',
      items_were_deleted: false,
      handlingError: {
				isError: false,
				message: ''
			}
    }
  }

  componentDidMount() {
    this.dropOrderItemState()
  }

  showError() {
		setTimeout(() => {
			this.setState({
				handlingError: {
					isError: false,
					message: ''
				}
			})
		}, 4000)

		return (
			<Alert color="danger">
				{this.state.handlingError.message}
			</Alert>
		)
	}

  onChange = e => {
		this.setState({[e.target.name]: e.target.value});
  };

  onChangeQuantity = (id, e) => {
    let items = this.state.order_items_for_sending.map(item => {
      if (item.id === id) {
        item.quantity = e
        return item
      } else {
        return item
      }
    })

    this.setState({order_items_for_sending: items})
  }

  deleteOrderItem = id => {
    let items = this.state.order_items_for_sending.filter(item => item.id !== id)
    this.setState({
      order_items_for_sending: items,
      items_were_deleted: true
    })
  }

  getFormData = () => {
    const notes = [];

    const reason = this.state.return_reason !== "other"
      ? this.state.return_reason
      : this.state.return_comment;

    notes.push(`Reason: "${reason}"`);

    if (this.state.return_option) {
      notes.push(`return option: "${this.state.return_option}"`);
    }

    if (this.state.return_additional_comments) {
      notes.push(`additional comments: "${this.state.return_additional_comments}"`);
    }

    return {
      order_id: this.props.orderInformationHeaderData.order_id,
      tracking_number: this.props.tracking_number,
      order_items: this.state.order_items_for_sending,
      email: this.state.return_email,
      notes: notes.join(', '),
      source: 'tracking app'
    }
  }

  sendForm = () => {
    this.setState({creating_return_in_progress: true})

    let data = this.getFormData()

    axios({
      url: `${API_TRACKING}return/`,
      method: 'POST',
      data: data
    }).then((res) => {
      this.props.waitingForLabel(res.data.reference)
      this.setState({
        return_order_ref: res.data.reference,
        return_order_tracking_number: res.data.fulfillments?.[0]?.tracking_number,
        creating_return_in_progress: false
      })
    }).catch((error) => {
      if (error.response) {
        let errorMessage;
        if (error.response.data.non_field_errors?.length > 0) {
          errorMessage = error.response.data.non_field_errors.join('\r\n');
        } else {
          for (const [index, element] of Object.entries(error.response.data)) {
            errorMessage = `${index} - ${element}`
          }
        }

        this.setState({
          handlingError: {
            isError: true,
            message: errorMessage
          },
          creating_return_in_progress: false
        })
      }
    });
  }

  dropOrderItemState = () => {
    let items = JSON.parse(JSON.stringify(this.props.order_items))
    this.setState({
      order_items_for_sending: items,
      items_were_deleted: false
    })
  }

  dropOrderItemStateOnToggle = () => {
    this.props.toggleModal()
    this.dropOrderItemState()
  }

  renderConfirmBtn() {
    if (this.state.creating_return_in_progress) {
      return (
        <button className="btn btn-primary disabled in-progress"><Loader alt light/>Creating return order</button>
      )
    } else if (this.props.loading_label) {
      return (
        <button className="btn btn-primary disabled in-progress"><Loader alt light/>Preparing the shipping label</button>
      )
    } else {
      return (
        <button className="btn btn-primary" onClick={this.sendForm}>Confirm</button>
      )
    }
  }

  showReturnReason() {
    return this.context?.config?.reason_to_return?.length;
  }

  showReturnOptions() {
    return this.context?.config?.return_options?.length;
  }

  showReturnComment() {
    if (!this.showReturnReason()) {
      return true;
    }

    return this.state.return_reason === "other";
  }

  getReasonsToReturn() {
    const reasons = this.context?.config?.reason_to_return ?? [];
    return reasons.concat("other");
  }

  render() {
    return (
      <Modal isOpen={this.props.modal} toggle={this.dropOrderItemStateOnToggle} className="returnModal" size="lg">
        <div className="modal-header">
          <div>Your return information</div>
          <button className='icon close' onClick={this.dropOrderItemStateOnToggle} />
        </div>
        <ModalBody>
          <div className="order return-order">
            <OrderInformationHeader data={this.props.orderInformationHeaderData} modal/>
          </div>
          <div className="return-product-wrap">
            <ProductsList
              order_items={this.state.order_items_for_sending}
              dropOrderItemState={this.dropOrderItemState}
              onChangeQuantity={this.onChangeQuantity}
              deleteOrderItem={this.deleteOrderItem}
              items_were_deleted={this.state.items_were_deleted}
              />
          </div>
          <div className="form-wrap">
            <Row>
              <Col xs={12}>
                {this.showReturnReason() && (
                  <FormGroup className="mb-4">
                    <Label for="return_reason">Reason for return</Label>

                    <Input
                      type="select"
                      name="return_reason"
                      id="return_reason"
                      className="form-select return-reason"
                      onChange={this.onChange}
                      value={this.state.return_reason}
                    >
                      <option value="" hidden/>

                      {this.getReasonsToReturn().map(reason => (
                        <option key={reason}>{reason}</option>
                      ))}
                    </Input>
                  </FormGroup>
                )}

                {this.showReturnOptions() && (
                  <FormGroup className="mb-4">
                    <Label for="return_option">How would you like us to process your return</Label>

                    <Input
                      type="select"
                      name="return_option"
                      id="return_option"
                      className="form-select return_options"
                      onChange={this.onChange}
                      value={this.state.return_option}
                    >
                      <option value="" hidden/>

                      {this.context?.config?.return_options?.map(option => (
                        <option key={option}>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                )}

                {this.context?.config?.enable_additional_comments && (
                  <FormGroup className="mb-4">
                    <Label for="return_additional_comments">Additional comments</Label>
                    <textarea
                      name="return_additional_comments"
                      id="return_additional_comments"
                      className="form-control"
                      onChange={this.onChange}
                      value={this.state.return_additional_comments}
                    >
                        &nbsp;
                      </textarea>
                  </FormGroup>
                )}

                {this.showReturnComment() && (
                  <FormGroup className="mb-4">
                    <Label for="return_comment">
                      Please provide the reason for return and any additional information
                    </Label>
                      <textarea
                        name="return_comment"
                        id="return_comment"
                        className="form-control"
                        onChange={this.onChange}
                        value={this.state.return_comment}
                      >
                        &nbsp;
                      </textarea>
                  </FormGroup>
                )}
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label for="return_email">Email (we will send a returns shipping label to this email address)</Label>
                  <Input
                    type="email"
                    name="return_email"
                    id="return_email"
                    onChange={this.onChange}
                    value={this.state.return_email}/>
                </FormGroup>
              </Col>
              <Col md={3}>
                <label>&nbsp;</label>
                {
                  this.renderConfirmBtn()
                }
              </Col>
            </Row>
          </div>
          <NestedModal
            return_email={this.state.return_email}
            closeAll={this.props.closeAll}
            toggle={this.props.toggleModal}
            nestedModal={this.props.nestedModal}
            toggleNested={this.props.toggleNested}
            toggleAll={this.props.toggleAll}
            return_order_ref={this.state.return_order_ref}
            return_order_tracking_number={this.state.return_order_tracking_number}
            getting_label_timeouted={this.props.getting_label_timeouted}
            getting_action_required={this.props.getting_action_required}
            created_shipping_label={this.props.created_shipping_label}
            />
          <div className="error-block">
            {this.state.handlingError.isError && this.showError()}
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default ReturnOrderModal;
