import React from "react";

export const themes = {
    default: {
        "config": {
            "links": {
                "site": "",
                "custom_links": []
            },
            "title": "",
            "favicon": "",
            "return_text": "Details and return",
            "font_color": "",
            "theme_color": "",
            "company_logo": "",
            "company_name": "",
            "success_text": ""
        },
        "name": "default"
    }
};

export const ClientThemeContext = React.createContext(themes.default);
