import React, {Component, Fragment} from "react";
import { Helmet } from 'react-helmet'
import axios from "axios";

import {TOKEN, API_TRACKING} from "./constants/index.js";
import { ClientThemeContext, themes } from "./constants/themes.js";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";

class App extends Component {
  static contextType = ClientThemeContext;

  constructor(props) {
    super(props);
    // Get the order referrence and tracking number from the URL and set them as default states.
    // This is how we pass order credentials to tracking app.
    // @tracking_number => the id after host name. Can be tracking number or order reference
    // @tracking_number_param => the param to tracking_number. Can be empty string if tracking number is actual tracking number.
    // If tracking number is order reference we pass tracking_number_param that is actual tracking number.
    // =============
    // This solution allows us speed up request to db and keep tracking more privacy without host restrictions.
		const urlPath = new URL(document.location.href);
		let tracking_number = urlPath.pathname.slice(1);
    let tracking_number_param = urlPath.searchParams.get('tracking_number')

		if (tracking_number.charAt(tracking_number?.length - 1) === '/') {
			tracking_number = tracking_number.substring(0, tracking_number?.length - 1)
		}

    this.state = {
      loading: true,
      clientDetails: {},
      tracking_number: tracking_number,
      tracking_number_param: tracking_number_param || ''
    }
  }

  componentDidMount() {
    let config = {
      headers: {
        "Authorization": TOKEN
      }
    }

    axios
      .get(`${API_TRACKING}${this.state.tracking_number}?tracking_number=${this.state.tracking_number_param}`, config)
      .then(res => {
        this.setState({
          clientDetails: res.data.preferences,
          loading: false
        })
        this.getClientTheme()
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            handlingError: {
              isError: true,
              message: error.response.status === 404 ? 'Sorry, your tracking number is incorrect' : error.message
            },
            loading: false
          })
        }
      });
  }

  getClientTheme() {
    let clientName = this.state.clientDetails?.name;

    if (clientName !== undefined) {
      if (themes[clientName]) {
        return themes[clientName]
      } else if (!themes[clientName]) {
        themes[clientName] = {...this.state.clientDetails};

        if (this.state.clientDetails.config?.theme_color) {
          document.documentElement.style.setProperty("--client-color", this.state.clientDetails.config.theme_color);
        }

        if (this.state.clientDetails.config?.font_color) {
          document.documentElement.style.setProperty("--client-font-color", this.state.clientDetails.config.font_color);
        }

        return themes[clientName]
      }
    }

    return themes.default
  }

  render() {
    let client_GTM_id = this.state?.clientDetails?.config?.google_tag_manager_id
    const company_name = this.context?.config?.company_name ? this.context?.config?.company_name : this.context?.name;
    return (
      <ClientThemeContext.Provider value={this.getClientTheme()}>
        <Helmet>
          <title>
            {
              this.context?.config?.title ?
              this.context?.config?.title :
              `Tracking | ${company_name}`
            }
          </title>
          <meta property="og:title" content={`Tracking | ${company_name}`} />
          <meta property="og:site_name" content={window.location.hostname} />
          <meta property="og:url" content={window.location.origin } />
          <meta property="og:description" content="Check the status of your most recent orders or process a return through our automated Order Tracking and Returns page." />
          <meta property="og:type" content="website" />
          <link rel="icon" href={this.state.clientDetails?.config?.favicon} />
          <script type='text/javascript'>
            {
              // add google tag manager script if user provide the GTM ID
              client_GTM_id &&
              `<!-- Google Tag Manager -->
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${client_GTM_id}');
              <!-- End Google Tag Manager -->`
            }
          </script>
        </Helmet>
        <Fragment>
          <Header />
          <Home
            tracking_number={this.state.tracking_number}
            tracking_number_param={this.state.tracking_number_param}
          />
          <Footer />
        </Fragment>
      </ClientThemeContext.Provider>
    );
  }
}

export default App;
