import React, {Component} from "react";
import axios from "axios";
import {Alert} from "reactstrap";
import { Helmet } from 'react-helmet'

import {API_TRACKING, TOKEN} from "../constants";
import { ClientThemeContext } from '../constants/themes';

import OrderCard from "./OrderCard";
import Loader from "./Loader";
import {Map} from "./Map";


class Home extends Component {
  static contextType = ClientThemeContext;

	constructor(props) {
		super(props);

    this.state = {
      orders: [],
      order: {},
      active_fulfilment: {},
      handlingError: {
        isError: false,
        message: ''
      },
      loading: true,
    }
  }

  componentDidMount() {
    if (this.props.tracking_number !== '') {
      this.getOrderInfo()
    }
  }

  showError() {
    setTimeout(() => {
      this.setState({
        handlingError: {
          isError: false,
          message: ''
        }
      })
    }, 4000)

    return (
      <Alert color="danger">
        {this.state.handlingError.message}
      </Alert>
    )
  }

  onChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  getOrderInfo = () => {
    let config = {
      headers: {
        "Authorization": TOKEN
      }
    }

    axios.get(`${API_TRACKING}${this.props.tracking_number}?tracking_number=${this.props.tracking_number_param}`, config)
      .then((res) => {
        /** Use fulfilment of return order if present, otherwise fallback to first order. */
        const activeOrder = res.data?.orders?.find(el => el.is_return) ?? res.data?.orders?.[0];
        const activeFulfilment = activeOrder?.fulfillments?.[0];

        this.setState({
          orders: res.data?.orders,
          order: res.data?.orders[0],
          active_fulfilment: activeFulfilment,
          loading: false
        });
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            handlingError: {
              isError: true,
              message: error.response.status === 404 ? 'Sorry, your tracking number is incorrect' : error.message
            },
            loading: false
          })
        }
      });
  };

  renderOrders() {
    const ordersList = this.state.orders.map(order => {
      return (
        <OrderCard
          key={order.id}
          loading={false}
          order={order} // need for change active order
          order_id={order.id}
          customer_order_ref={order.customer_reference}
          order_ref={order.reference}
          tracking_number={this.props.tracking_number}
          tracking_number_param={this.props.tracking_number_param}
          is_return_action_available={order.is_return_action_available}
          is_return={order.is_return}
          fulfillments={order.fulfillments}
          destination={order.destination_address}
          order_items={order.order_items}
          active_order={this.state.order.id === order.id ? true : false}
          getActiveFulfilment={this.getActiveFulfilment}
          active_fulfilment={this.state.active_fulfilment}
          getOrderInfo={this.getOrderInfo}
          getting_action_required={order.status === 'action_required' ? true : false}
          includesReturnOrder={this.state.orders.some(el => el.is_return)}
        />
      )
    })
    // sort the output as original order > related returns > another original order > related returns
    return ordersList.sort((a, b) => {
      if (a.props.order.customer_reference > b.props.order.customer_reference) {
        return 1;
      } else if (a.props.order.customer_reference < b.props.order.customer_reference) {
        return -1;
      } else {
        return 0
      }
    });
  }

  getActiveFulfilment = (fulfilment) => {
    this.setState({active_fulfilment: fulfilment})
  }

  renderEmpty() {
    return (
      <div className="empty-page-wrap">
        <div className="empty-page-content">
          {
            this.state.orders.length === 0 && this.props.tracking_number !== '' ?
              <div>
                <h2>Whoops, seems there is no such tracking number - <em>{this.props.tracking_number_param}</em> or such order - <em>{this.props.tracking_number}</em></h2>
                <p>Check if your entered number is correct or try another one. Thank you!</p>
              </div>
              :
              <div>
                <h2>Tracking and returns made easy!</h2>
                <p>Track your order or return your item with our carbon neutral returns service.</p>
              </div>
          }
        </div>
      </div>
    )
  }

  renderContent() {
    const orders = this.state.orders;
    const hasMap = this.state.order.reference.includes('GVA');
    const vesselTracking = this.state.active_fulfilment?.tracking_event?.vessel_tracking;

    return (
      orders && orders.length > 0 &&
      <div className=' main-content'>
        <div className="order__list-wrap">
          <div className='order__list'>
            {
              this.renderOrders()
            }
          </div>
        </div>
        { hasMap && !!vesselTracking && (
          <Map
            order={this.state.order}
            vesselTracking={vesselTracking}
          />
        )}
      </div>
    )
  }

  renderDispatcher() {
    if (this.state.loading && !this.state.handlingError.isError && this.props.tracking_number) {
      return <Loader />
    } else if (!this.state.handlingError.isError && this.props.tracking_number && this.state.orders.length !== 0) {
      return this.renderContent()
    } else {
      return this.renderEmpty()
    }
  }

  render() {
    const company_name = this.context?.config?.company_name ? this.context?.config?.company_name : this.context?.name;
    return (
      <div className="site-body">
        <Helmet>
          {
            this.props.tracking_number ?
              <title>{`${company_name} | Tracking number: ${this.props.tracking_number}`}</title> :
              <title>{this.context?.config?.title}</title>
          }
        </Helmet>
        {
          this.renderDispatcher()
        }

      </div>
    );
  }
}

export default Home;
