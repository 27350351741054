import {Component} from "react";
import axios from "axios";
import ReturnOrderModal from "./ReturnOrderModal";
import OrderInformationHeader from "./OrderInformationHeader";
import {API_TRACKING, TOKEN} from '../constants/index';
import {Alert} from "reactstrap";
import Fulfillments from "./Fulfillments";
import { ClientThemeContext } from '../constants/themes';

class OrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nestedModal: false,
      closeAll: false,
      loading_label: false,
      getting_label_timeouted: false,
      getting_action_required: false,
      handlingError: {
				isError: false,
				message: ''
			},
      isOrderOpened: false,
      created_shipping_label: ''
    }
  }

  showError() {
		setTimeout(() => {
			this.setState({
				handlingError: {
					isError: false,
					message: ''
				}
			})
		}, 4000)

		return (
			<Alert color="danger">
				{this.state.handlingError.message}
			</Alert>
		)
	}

  waitingForLabel = (created_return_ref) => {
    // Start the recursion timer with GET requests to API every 5 sec.
    // If it responce with valid data show the window with return order info and shipping label.
    // If it will not responce with valid data during one minute stop sending requests and just show the message that it will be send to email.

    let delay = 5000;
    let tries = 7;
    this.setState({loading_label: true})

    // Axios request params:
    // @created_return_ref - newly created return order reference => TODO: change to tracking number
    // @active_fulfilment.tracking_number - original order tracking number

    let get_return_order = () => {
      axios.get(`${API_TRACKING}${created_return_ref}?tracking_number=${created_return_ref}`,
      {
        headers: { "Authorization": TOKEN }
      }).then(res => {
        let created_return = res.data.orders.filter((order) => {
          return order.reference === created_return_ref
        })

        if (created_return?.[0]?.status === 'action_required') {
          clearTimeout(timerId)
          this.setState({
            getting_action_required: true,
            loading_label: false,
          })
          this.toggleNested()
          return false;
        }

        // check if data is valid
        if (created_return.length > 0 && created_return?.[0]?.fulfillments?.[0]?.shipping_label !== null) {
          clearTimeout(timerId)
          this.setState({
            loading_label: false,
            created_shipping_label: created_return?.[0]?.fulfillments?.[0]?.shipping_label
          })
          this.toggleNested()
          return false;
        }

        // stop after a bit less then 1 minute
        if (tries < 1) {
          clearTimeout(timerId)
          this.setState({
            getting_label_timeouted: true,
            loading_label: false
          })
          this.toggleNested()
          return false;
        }
      }).catch((error) => {
        if (error.response) {
          this.setState({
            handlingError: {
              isError: true,
              message: error.message
            },
            loading_label: false
          })
        }
      });

      tries = tries - 1
      timerId = setTimeout(get_return_order, delay)
    };

    let timerId = setTimeout(get_return_order, delay);
  }

  toggleModal = () => {
    this.setState({modal: !this.state.modal})
  }

  toggleNested = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false
    });
  }

  toggleAll = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: true
    })
  }
  toggleOrder = () => this.setState({
    isOrderOpened: !this.state.isOrderOpened
  })

  get orderType() {
    if (this.props.is_return) {
      return 'Return order';
    }

    if (this.props.order.order_type === 'asn') {
      return 'ASN order';
    }

    return 'Your order';
  }

  renderProductList(order_items) {
    let products = order_items.map(item => {
      return (
        <div className="order__item" key={item.id}>
          {item.image ?
            <div className="order__item-image"><img src={item.image} alt="" width='40' /></div>
            :
            <div className="order__item-image image--empty"></div>
          }
          <div className="order__item-name">{item.title}</div>
          <div className="order__item-quantity"> x {item.quantity}</div>
        </div>
      )
    });

    return products;
  }

  renderReturnBtn() {
    if (
      this.context.config.enable_return_button &&
      this.props.is_return_action_available &&
      !this.props.includesReturnOrder
    ) {
        return (
          <div className="order__actions">
            <button type="button"
                    onClick={this.toggleModal}>
              {
                this.context?.config?.return_text ?
                this.context?.config?.return_text :
                'Details and Return'
              }
            </button>
          </div>
        )
    }
  }

  render() {
    let orderInformationHeaderData = {
      order_id: this.props.order_id,
      order_ref: this.props.customer_order_ref || this.props.order_ref,
      tracking_destination: this.props.destination,
      courier_service: this.props.fulfillments?.[0]?.courier_service?.service_name,
      courier_name: this.props.fulfillments?.[0]?.shipping_account?.name,
      courier_logo: this.props.fulfillments?.[0]?.shipping_account?.courier?.logo,
    }

    return (
      <div className="order__wrapper">
        <div className={`order__top ${this.props.is_return ? "return" : ""}`}>
          {`${this.orderType} details`}
        </div>
        <div className={`order`}>
          <OrderInformationHeader
            data={orderInformationHeaderData}
            fulfilment={this.props.active_fulfilment}
            is_return={this.props.is_return}
            tracking_number={this.props.tracking_number}
            getOrderInfo={this.props.getOrderInfo}
            getting_action_required={this.props.getting_action_required}
            />
            <div className="order__body">
              <div className="order__item-list-wrap">
                <div className="order__item-list">
                  {
                    this.renderProductList(this.props.order_items)
                  }
                </div>
              </div>
            </div>
            <Fulfillments
              tracking_number={this.props.tracking_number}
              order={this.props.order}
              fulfillments={this.props.fulfillments}
              active_fulfilment={this.props.active_fulfilment}
              />
            {
              this.renderReturnBtn()
            }
            <ReturnOrderModal
              modal={this.state.modal}
              nestedModal={this.state.nestedModal}
              closeAll={this.state.closeAll}
              toggleModal={this.toggleModal}
              toggleNested={this.toggleNested}
              toggleAll={this.toggleAll}
              orderInformationHeaderData={orderInformationHeaderData}
              tracking_number={this.props.active_fulfilment.tracking_number}
              order_items={this.props.order_items}
              order_registered_email={this.props.destination.email}
              waitingForLabel={this.waitingForLabel}
              loading_label={this.state.loading_label}
              getting_label_timeouted={this.state.getting_label_timeouted}
              getting_action_required={this.state.getting_action_required}
              created_shipping_label={this.state.created_shipping_label}
            />
        </div>
      </div>
    )
  }
}

OrderCard.contextType = ClientThemeContext;
export default OrderCard;
